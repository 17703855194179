import { render, staticRenderFns } from "./error.vue?vue&type=template&id=286d9ee6&scoped=true"
import script from "./error.vue?vue&type=script&lang=ts"
export * from "./error.vue?vue&type=script&lang=ts"
import style0 from "./error.vue?vue&type=style&index=0&id=286d9ee6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@2.8.8_react-do_she2y5ovbulgtnxrn3wns6uug4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "286d9ee6",
  null
  
)

export default component.exports