export enum FeatureFlags {
  NEW_GTM_DEBUG_MODE = 'feature_new_gtm_debug_mode',
  BLACK_FRIDAY_PROMO = 'feature_black_friday',
  BLACK_SEPTEMBER_PROMO = 'feature_black_september',
  BLACK_APRIL_PROMO = 'feature_black_april',
  SUMMER_DEALS_PROMO = 'feature_summer_deals',
  VALENTINE_PROMO = 'feature_valentine_theme',
  SPRING_THEME = 'feature_spring_theme',
  EASTER_THEME = 'feature_easter_theme',
  MAY_THEME = 'feature_may_theme',
  SUMMER_THEME = 'feature_summer_theme',
  NEW_MAIN_BANNER = 'feature_new_main_banner',
  B2B_PAGE = 'feature_b2b_page',
  NEW_VERIFY_ID_PROCESS = 'feature_new_verify_id_process',
  NEW_YEAR = 'feature_new-year',
  NEW_VOUCHER_PROCESS = 'feature_new-voucher-process',
  AMP_ADDRESSES = 'feature_amp_addresses',
  AMP_BASE_INFO = 'feature_amp_base_info',
  AMP_COMPARATOR = 'feature_amp_comparator',
  HUBSPOT_CHAT = 'feature_hubspot_chat',
  DIXA_CHAT = 'feature_dixa_chat',
  DEALS_OF_THE_DAY_WIDGET = 'feature_deals_of_the_day_widget',
  STATIC_EXIT_INTENT_POPUP = 'feature_static_exit_intent_popup',
  IFRAME_OFFER_CARD_PAGE = 'feature_iframe_offer_card_page',
}
